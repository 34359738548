<i18n>
{
  "en": {
    "structureTitle": "Trend tool",
    "structureSubTitle": "Select the Deck sensors to view the data trend over time."

  },
  "it": {
    "structureTitle": "Tool Trends",
    "structureSubTitle": "Seleziona i sensori Deck di cui visualizzare l’andamento dei dati nel tempo."
  }
}
</i18n>
<template>
  <div>
    <PageTemplate :hideTips="true">
      <template v-slot:nav>
        <DateRangeSelect
          :initDateRange="getDateRange"
          @date-selected="setDateRange"
          @structure-selected="selectStructure"
          :selectedStructure="getStructureDetails"
          :availableStructures="getStructures"
        />
      </template>
      <template v-slot:content>
        <!-- Modulo Struttura -->
        <Generic
          id="generic"
          :title="$t('structureTitle')"
          :subTitle="$t('structureSubTitle')"
          v-if="getStructureDetails && getStructureDetails.type === 'generic'"
          :selectedStructure="getStructureDetails"
          :multiSensorSelect="true"
          :typesFilter="['deck']"
          @sensors-selected="setSensorsPool"
          @refresh="fetchStructureData"
        />
        <BridgeInfo
          id="bridge-inf"
          :typesFilter="['deck']"
          :multiSensorSelect="true"
          :structureDetails="getStructureDetails"
          v-if="getStructureDetails && getStructureDetails.type != 'generic'"
          :selectedSensors="getSensorPool"
          @sensors-selected="setSensorsPool"
        />
        <!-- Grafici dei Trend -->
        <Trend v-if="getStructureDetails" />
      </template>
    </PageTemplate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import PageTemplate from "../PageTemplate.vue";
import DateRangeSelect from "../../components/ui/DRSelect.vue";
import BridgeInfo from "../../components/modules/dashboard/BridgeInfo.vue";
import Trend from "../../components/modules/dashboard/Trend.vue";
import Generic from "../../components/structures/Generic.vue";

export default {
  name: "TrendV2",
  components: {
    PageTemplate,
    DateRangeSelect,
    BridgeInfo,
    Trend,
    Generic,
  },
  data() {
    return {
      loaded: true,
    };
  },
  methods: {
    ...mapActions({
      fetchStructures: "structure/fetchStructures",
      selectStructure: "structure/selectStructure",
      fetchStructureData: "structure/fetchStructureData",
      setSensorsPool: "trend/setSensorsPool",
      logout: "auth/logout",
      fetchUserSettings: "user/fetchUserSettings",
      setDateRange: "trend/setDateRange",
      fetchTrendAPI: "trend/fetchTrendAPI",
    }),
  },
  computed: {
    ...mapGetters({
      getStructures: "structure/getStructures",
      getSensors: "structure/getSensors",
      getSelectedStructure: "structure/getSelectedStructure",
      getStructureDetails: "structure/getStructureDetails",
      getSensorPool: "trend/getSensorPool",
      getDateRange: "trend/getDateRange",
      getUserType: "user/getUserType",
    }),
  },
  async mounted() {
    await this.setDateRange([
      // 240 Hours
      new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
      new Date(),
    ]);
    //this.actualDateRange = [this.getTrendDateRange[0], this.getTrendDateRange[1]];
    //await this.setSensorsPool(this.getSensors.filter((s) => s.type === "deck"));
    await this.fetchTrendAPI();
  } /*
  destroyed(){
    clearInterval(this.refreshingInterval);
  } */,
  watch: {
    getSensorPool: async function() {
      await this.fetchTrendAPI();
    },
    getDateRange: async function() {
      await this.fetchTrendAPI();
    },
  },
};
</script>
<style scoped>
#status-row {
  width: 92%;
  margin: auto;
  display: grid;
  grid-template-columns: 6.5fr 3.5fr;
  column-gap: 20px;
  grid-template-rows: min-content;
}
#generic {
  margin-top: 20px;
}
#bridge-ow {
  max-width: 100vw;
}
</style>
