<template>
  <div class="bridge-info-block noselect">
    <!-- Ponte e Sensors in overlay -->
    <div class="bridge-draw-block">
      <BridgeDraw
        :spans="getStructureDetails.spans"
        :multiSensorSelect="multiSensorSelect"
        :sensors="getStructureDetails.sensors"
        :selectedFromParent="selectedSensors[0]"
        :selectedStructure="getStructureDetails"
        :typesFilter="typesFilter"
        @sensor-selected="selectSensor"
        @sensors-selected="selectSensors"
      />
    </div>
    <!-- Google Map -->
    <TravelMap class="travel-map" :coordinates="getStructureDetails.coordinates" />
  </div>
</template>
<script>
import TravelMap from "../../ui/TravelMap.vue";
import BridgeDraw from "../../ui/BridgeDraw.vue";
import { mapGetters } from "vuex";

export default {
  name: "bridge-info",
  components: {
    BridgeDraw,
    TravelMap,
  },
  props: {
    typesFilter: Array,
    multiSensorSelect: Boolean,
    selectedSensors: Array,
  },
  computed: {
    ...mapGetters({
      getStructureDetails: "structure/getStructureDetails",
    }),
  },
  methods: {
    selectSensors(sensors) {
      this.$emit("sensors-selected", sensors);
    },
    selectSensor(sensor) {
      this.$emit("sensor-selected", sensor);
    },
  },
};
</script>
<style scoped>
/* Root Wrapper */
.bridge-info-block {
  position: relative;
  height: 520px;
  min-width: 1000px;
  width: inherit;
  margin-bottom: 20px;
  margin-top: 40px;
  display: grid;
  grid-template-columns: minmax(70%, 80%) minmax(400px, 20%);
  grid-template-rows: 560px;
  z-index: 2;
  justify-items: center;
  align-content: center;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
}

@media all and (max-width: 1499px) and (min-width: 300px) {
  .bridge-info-block {
    height: 420px;
  }
}

/* Google Map */
.travel-map {
  width: 100%;
  height: 440px;
  justify-self: end;
  filter: drop-shadow(rgba(0, 0, 0, 0.8) 1px 6px 8px);
  padding-top: 60px;
  grid-row: 1;
  grid-column: 2;
  border-radius: 12px;
  z-index: 9;
}

@media all and (max-width: 1499px) and (min-width: 300px) {
  .travel-map {
    height: 340px;
    padding-top: 110px;
  }
}

/* Bridge Wrapper */
.bridge-draw-block {
  margin-top: 36px;
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  max-width: 100%;
  min-width: 772px;
}

/* Scrollbar */
body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
</style>
